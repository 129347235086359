app.service('main', function ($global, scroll, editor, fck, build, tabsView, lastRevisions, loading, 
	searchViews, download, ajaxPage, autocomplete, actions, html, login, listForm, editForm, menu,
	broker, cookie, modal, colorPicker, redactor, audit, datelocal, flashMessage, captchaConsole,
	forgotPassword, uploadImage){
	var app = build.app;

	$().ready(function() {
		init();
	});
	if (window.location.href.indexOf('#topBarWidgetOn') > -1) {
		cookie.set('topBarWidget', 'true')
	}
	if (cookie.get('topBarWidget')) {
		html.add('show-production-message')
	}
	function isFront(front) {
		if (front != null) {
			return front === app;
		} else {
			return app;
		}
	}
	$global('front', isFront);

	function init() {
		modal.init();

		$('body').on('click', 'a[href="#"]', function(event) {
			event.preventDefault();
		});

		addEventListener('pageshow', (event) => { loading.stop() });

		addEventListener('change', (event) => { uploadImage.processFileUploadChangeEvent(event) });

		if(cookie.get('atrUser')=='js'){
			html.add('atr-mode');
		}

		var controlButtons = $('#page-actions a').clone().slice(0, 3);
		var $controlMenu = $('<div id="sticky-menu"/>').append(controlButtons);
		$('#module-menu').append($controlMenu);
		var $body = $('body');
		var $header = $('#header');
		$(window).scroll(function() {
			requestAnimationFrame(function() {
				if ($(window).scrollTop() > $header.height()) {
					$body.addClass('sticky');
				} else {
					$body.removeClass('sticky');
				}
			});
		});

		
		if (jQuery('span#file.errors').length == '1') {				//przeniesione z EURO
			dataLayer.push({ 'event': 'Wgrano zbyt ciężki plik' });		//przeniesione z EURO
		}																//przeniesione z EURO

		login.init();
		forgotPassword.init();
		searchViews.init();
		actions.init();
		audit.init();
		editor.init();
		fck.init();
		redactor.init();
		download.init();
		datelocal.init();
		menu.init();
		colorPicker.init();
		
		flashMessage.init();
		ajaxPage.init();
		tabsView.init();
		scroll.init();
		captchaConsole.init();
		moduleInit();

		// broker.on('AJAX_PAGE_SHOW', function(){
		// 	moduleInit(true);
		// });
		broker.on('MODAL_PAGE_RELOAD', function(){
			autocomplete.init();
		});
		
		broker.on('MODAL_PAGE_SHOW', function(){
			moduleInit({modal:true});
		});
		broker.on('AJAX_CONTENT_LOADED', function (event, $element) {
			editor.init($element);
			fck.init($($element));
			redactor.init($element);
			editForm.init('.tabs-view');
		});

		
	}
	function moduleInit(options){
		options = $.extend({
			modal: false,
			ajax: false,
			modal: false,
			url: false
		}, options);
		listForm.init({ id: (options.modal ? '#modal-list-form' : '#list-form')});
		editForm.init(options.modal ? '#modal-edit-form' : '#edit-form');
		lastRevisions.load(options.modal ? '#modal-last-revisions' : '#last-revisions');
		autocomplete.init();
		$('body').attr('wdio-random', parseInt(Math.random() * 100000000));
	}
});

window.dataLayer = window.dataLayer || [];						//przeniesione z EURO